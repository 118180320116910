
        <ul id="navbar-list" class="navbar-nav override">

    <li class="nav-item override" (click)="onNavLinkClicked($event)">
        <a routerLink="/theproject" class="nav-link override"             id="navBarCustom"
>CONOCE LA MISIÓN</a>
    </li>
    <li class="nav-item override" (click)="onNavLinkClicked($event)">
        <a routerLink="/colaboradores" class="nav-link override"             id="navBarCustom"
>COLABORADORES</a>
    </li>
    <li class="nav-item override" (click)="onNavLinkClicked($event)">
        <a routerLink="/organizaciones" class="nav-link override"             id="navBarCustom"
>ORGANIZACIONES AMIGAS</a>
    </li>
    <li class="nav-item override" (click)="onNavLinkClicked($event)">
        <a routerLink="/biblioteca" class="nav-link override"             id="navBarCustom"
>BIBLIOTECA</a>
    </li>
    <li class="nav-item override" (click)="onNavLinkClicked($event)">
        <a routerLink="/contact" class="nav-link override"             id="navBarCustom"
>CONTACTO</a>
    </li>
</ul>
<span class="drawer-inset-shadow"></span>
