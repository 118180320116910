import { NgModule } from '@angular/core';
import { Routes, RouterModule, PreloadAllModules } from '@angular/router';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { LayoutComponent } from './layout/components/layout.component';

const routes: Routes = [
  {
    path: '',
    component: LayoutComponent,
    children: [
      {
        redirectTo: '/home',
        pathMatch: 'full',
        path: '',
      },
      {
        path: 'home',
        loadChildren: () =>
          import('./home/home.module').then((m) => m.HomeModule),
      },
      {
        path: 'theproject',
        loadChildren: () =>
          import('./the-project/the-project.module').then(
            (m) => m.TheProjectModule
          ),
      },

      {
        path: 'organizaciones',
        loadChildren: () =>
          import('./organizaciones/organizaciones.module').then(
            (m) => m.OrganizacionesModule
          ),
      },

      {
        path: 'contact',
        loadChildren: () =>
          import('./contact/contact.module').then((m) => m.ContactModule),
      },
      {
        path: 'colaboradores',
        loadChildren: () =>
          import('./colaboradores/colaboradores.module').then(
            (m) => m.ColaboradoresModule
          ),
      },
      {
        path: 'biblioteca',
        loadChildren: () =>
          import('./biblioteca/biblioteca.module').then(
            (m) => m.BibliotecaModule
          ),
      },
    ],
  },
  {
    path: '**',
    component: PageNotFoundComponent,
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules }),
  ],

  exports: [RouterModule],
})
export class AppRoutingModule {}
