import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-the-project',
  templateUrl: './the-project.component.html',
  styleUrls: ['./the-project.component.scss']
})
export class TheProjectComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
