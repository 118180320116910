import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { TheProjectRoutingModule } from './the-project-routing.module';
import { TheProjectComponent } from './components/the-project.component';

@NgModule({
  declarations: [TheProjectComponent],
  imports: [CommonModule, TheProjectRoutingModule],
})
export class TheProjectModule {}
