<div class="container">
  <div class="row align-items-center">
    <div class="col align-self-center">
      <ul>
        <li>
          <a href="">
            <img src="../../../../assets/images/instagram.png" alt="" />
          </a>
        </li>
        <li>
          <a href="">
            <img src="../../../../assets/images/facebook.png" alt="" />
          </a>
        </li>
        <li>
          <a href="">
            <img src="../../../../assets/images/youtube.png" alt="" />
          </a>
        </li>
        <li>
          <a href="">
            <img src="../../../../assets/images/linkedin.png" alt="" />
          </a>
        </li>
      </ul>
      <h3 style="text-align: center;">
        © 2021 GUARDIANES DE LA PALMA
      </h3>
    </div>
  </div>
</div>
