<header>
  <nav class="navbar navbar-expand-md" id="navbar">
    <div class="container">
      
      <a routerLink="/home"
        ><img
          src="../../../../assets/images/logoPalma.png"
          alt=""
          style="margin: 20px"
        />
      </a>
      <div class="nav-toggler" [class.drawer-open]="isDrawerOpen" id="nav-toggler" (click)="toggleNavDrawer(!isDrawerOpen)">
          <span></span>
          <span></span>
          <span></span>
          <span></span>
      </div>
      <app-nav-drawer [isDrawerOpen]="isDrawerOpen" (drawerToggleEmitter)="toggleNavDrawer($event)"></app-nav-drawer>
    </div>
  </nav>
</header>