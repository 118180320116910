<div class="container">
  <div class="row">
        <div class="row align-items-center">

    <div class="col-md-12 -md-center">
      <a routerLink="/home"
        ><img
          src="../../../../assets/images/logoPalma.png"
          alt=""
          style="margin: 20px"
        />
      </a>
    </div>
        </div>
    <div class="row align-items-center">
      <div class="col-md-12 -md-center">
        <nav class="nav nav-pills flex-column flex-sm-row" id="spaceNavBar">
          <a
            class="flex-sm-fill text-sm-center nav-link active"
            id="navBarCustom"
            routerLink="/home"
            routerLinkActive="active"
            >GUARDIANES DE LA PALMA</a
          >

          <a
            class="flex-sm-fill text-sm-center nav-link active"
            id="navBarCustom"
            routerLink="/theproject"
            routerLinkActive="active"
            >CONOCE LA MISIÓN</a
          >

          <a
            class="flex-sm-fill text-sm-center nav-link active"
            id="navBarCustom"
            routerLink="/colaboradores"
            routerLinkActive="active"
            >COLABORADORES</a
          >
          <a
            class="flex-sm-fill text-sm-center nav-link active"
            id="navBarCustom"
            routerLink="/organizaciones"
            routerLinkActive="active"
          >
            ORGANIZACIONES AMIGAS
          </a>
          <a
            class="flex-sm-fill text-sm-center nav-link active"
            id="navBarCustom"
            routerLink="/biblioteca"
            routerLinkActive="active"
          >
            BIBLIOTECA
          </a>
          <a
            class="flex-sm-fill text-sm-center nav-link active"
            id="navBarCustom"
            routerLink="/contact"
            routerLinkActive="active"
            >CONTACTO</a
          >
        </nav>
      </div>
    </div>
    <!-- <div class="col col-lg-2">
      <a class="myButton" routerLink="/shop" routerLinkActive="active"
        >Tienda</a
      >
    </div> -->
  </div>
</div>
