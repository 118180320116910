<div class="banner_area">
  <div class="banner_inner d-flex align-items-center">
    <div
      class="overlay bg-parallax"
      data-stellar-ratio="0.9"
      data-stellar-vertical-offset="0"
      data-background=""
    ></div>
    <div class="container">
      <div class="banner_content text-center">
        <h1 id="main_title">
          Somos personas, organizaciones y empresas unidas para proteger y
          mejorar el bienestar del ecosistema de las palmas ubicadas en el
          santuario de la naturaleza <br />
          <strong>“Palmar el Salto”.</strong>
        </h1>
      </div>
    </div>
  </div>
</div>
<!--================ End Home Banner Area =================-->

<!--================ Start Causes Area =================-->

<!--================ End Causes Area =================-->

<!-- <div class="container">
  <div class="section">
    <h3 class="mb-30 title_color">Left Aligned</h3>
    <div class="row">
      <div class="col-md-3">
        <img src="../../../assets/images/18.JPEG" alt="" class="img-fluid" />
      </div>
      <div class="col-md-9 mt-sm-20 left-align-p">
        <p>
          Recently, the US Federal government banned online casinos from
          operating in America by making it illegal to transfer money to them
          through any US bank or payment system. As a result of this law, most
          of the popular online casino networks such as Party Gaming and
          PlayTech left the United States. Overnight, online casino players
          found themselves being chased by the Federal government. But, after a
          fortnight, the online casino industry came up with a solution and new
          online casinos started taking root. These began to operate under a
          different business umbrella, and by doing that, rendered the transfer
          of money to and from them legal. A major part of this was enlisting
          electronic banking systems that would accept this new clarification
          and start doing business with me. Listed in this article are the
          electronic banking systems that accept players from the United States
          that wish to play in online casinos.
        </p>
      </div>
    </div>
  </div>
  <div class="section text-right">
    <h3 class="mb-30 title_color">Right Aligned</h3>
    <div class="row">
      <div class="col-md-9">
        <p class="text-right">
          Over time, even the most sophisticated, memory packed computer can
          begin to run slow if we don’t do something to prevent it. The reason
          why has less to do with how computers are made and how they age and
          more to do with the way we use them. You see, all of the daily tasks
          that we do on our PC from running programs to downloading and deleting
          software can make our computer sluggish. To keep this from happening,
          you need to understand the reasons why your PC is getting slower and
          do something to keep your PC running at its best. You can do this
          through regular maintenance and PC performance optimization programs
        </p>
        <p class="text-right">
          Before we discuss all of the things that could be affecting your PC’s
          performance, let’s talk a little about what symptoms
        </p>
      </div>
      <div class="col-md-3">
        <img src="../../../assets/images/17.JPEG" alt="" class="img-fluid" />
      </div>
    </div>
  </div>
</div> -->

<section class="causes_area">
  <div class="container">
    <div class="main_title">
      <h3>NUESTRA MISIÓN ES PROTEGER LA PALMA DE:</h3>
    </div>
    <div class="row">
      <div class="col-lg-6 col-md-6">
        <div class="single_causes">
          <h4>Incendios forestales
</h4>
          <img src="../../../assets/images/incendio.png" alt="" />
          <p>
            La supervivencia de la palma está en alto riesgo, por el impacto anual de los incendios estivales originados en colinas y microcuencas periféricas de las ciudades de Valparaíso y Viña del Mar.
Sobrevive en gran parte esta palmas al régimen de incendios permanentes porque en estado adulto no se ve muy afectada por el fuego(Villaseñor, Saiz, 1993). Sin embargo el hábitat del bosque esclerófilo en el cual vive, que se degrada y se incendia regularmente, se va transformando en un paisaje fragmentado que ocasiona un cambio en las condiciones ecológicas bajo las cuales vive esta planta.

          </p>
        </div>
      </div>

      <div class="col-lg-6 col-md-6">
        <div class="single_causes">
          <h4>Extracción de cocos
</h4>
          <img src="../../../assets/images/extraccion_cocos.png" alt="" />
          <p>
            Esta casi nula posibilidad de regeneración de JubaeachilensisMoll. Baillonse debe a la herbivoría, a la constante extracción que hace el hombre de la semilla comestible (drupa o coquitos) en épocas de floración (meses de junio y julio) que ocasiona que esta palma no tenga mayores posibilidades de generar poblaciones juveniles en estas condiciones (Quintanilla, Castillo, 2009).
          </p>
        </div>
      </div>
    </div>
  </div>
</section>

<div class="banner_area2">
  <div class="banner_inner2 d-flex align-items-center">
    <div
      class="overlay bg-parallax"
      data-stellar-ratio="0.9"
      data-stellar-vertical-offset="0"
      data-background=""
    ></div>
    <div class="container">
      <div class="banner_content2 text-center">
        <!-- <p>
          If you are looking at blank cassettes on the web, you may be very
          confused at the difference in price You may see some for as low as
          each. If you are looking at blank cassettes on the web, you may be
          very confused at the difference in price You may see. If you are
          looking at blank cassettes on the web, you may be very confused at the
          difference in price You may see some for as low as each. If you are
          looking at blank cassettes on the web, you may be very confused at the
          difference in price You may see.
        </p> -->
        <div class="row">
          <div class="col-lg-12">
            <blockquote class="generic-blockquote">
              Hoy le pedimos a la autoridad municipal que haga un cambio y
              decrete una ordenanza municipal que prohíba el tránsito de
              vehículos motorizados en el cortafuegos de calle granada, así se
              disminuirá el impacto que hacemos al bosque y es un primer paso
              para promover la actividad científica y turística en el palmar el
              salto.
            </blockquote>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="container">
  <div class="row align-items-center">
    <div class="col text-center">
      <button class="myButton">
        <a
          href="https://www.change.org/p/mmachile-camaradiputados-senado-chile-modificar-la-ley-20283-para-que-todos-los-%C3%A1rboles-nativos-obtengan-protecci%C3%B3n-del-estado" target="_blank"
        >
          FIRMA LA CAUSA!
        </a>
      </button>
      <button routerLink="/contact"
      class="myButtonDona">CONTACTANOS</button>
    </div>
  </div>
</div>

<!--================ <section class="causes_area">
  <div class="container">
    <div class="main_title">
      <h2>Our major causes</h2>
      <p>Creepeth called face upon face yielding midst is after moveth</p>
    </div>
    <div class="row">
      <div class="col-lg-4 col-md-6">
        <div class="single_causes">
          <h4>Give Donation</h4>
          <img src="../../../assets/images/c1.png" alt="" />
          <p>
            It you're. Was called you're fowl grass lesser land together waters
            beast darkness earth land whose male all moveth fruitful.
          </p>
        </div>
      </div>
      <div class="col-lg-4 col-md-6">
        <div class="single_causes">
          <h4>Give Inspiration</h4>
          <img src="../../../assets/images/c2.png" alt="" />
          <p>
            It you're. Was called you're fowl grass lesser land together waters
            beast darkness earth land whose male all moveth fruitful.
          </p>
        </div>
      </div>
      <div class="col-lg-4 col-md-6">
        <div class="single_causes">
          <h4>Become Bolunteer</h4>
          <img src="../../../assets/images/c3.png" alt="" />
          <p>
            It you're. Was called you're fowl grass lesser land together waters
            beast darkness earth land whose male all moveth fruitful.
          </p>
        </div>
      </div>
    </div>
  </div>
</section> =================-->
